import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AppContext from '../context/AppContext';
import docimage from '../img/docimage.jpg';
import pdfimage from '../img/pdfimage.jpg';
import AddressInput from './AddressInput';
import AlertModal from './AlertModal';
import FileUpload from './FileUpload';
/*
declare global {
  interface Window {
    google: any;
  }
}
*/

interface DataProps {
  pagename: string;
}
interface MyData {
  [key: string]: any;
}
interface AddressComponents {
  street: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

const DataForm: React.FC<DataProps> = (pagename) => {
  const [formData, setFormData] = useState<Record<string, any>>({});
  const [dataChanges, setDataChanges] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [currentrow, setCurrentRow] = useState<number>(1);
  const { sessionData } = useContext(AppContext);
  const streetRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);
  const zipcRef = useRef<HTMLInputElement>(null);
  const countryRef = useRef<HTMLInputElement>(null);


  function isEmptyObj(obj: Record<string, any> | null): boolean {
    return (obj == null) || (Object.keys(obj).length === 0 && obj.constructor === Object);
  }
  const sortObjectKeys = (data: MyData): string[] => {
    const keys = Object.keys(data);
    keys.sort((key1, key2) => {
      const index1 = data[key1].columnIndex;
      const index2 = data[key2].columnIndex;
      return index1 - index2;
    });
    return keys;
  };
  function ObjectsAreEqual(object1: any, object2: any) {
    if (isEmptyObj(object1) && isEmptyObj(object2)) {
      return true;
    } else if (isEmptyObj(object1) || isEmptyObj(object2)) {
      return false;
    }
    const objectKeys1 = Object.keys(object1);
    const objectKeys2 = Object.keys(object2);

    if (objectKeys1.length !== objectKeys2.length) {
      return false;
    }
    for (const key of objectKeys1) {
      const value1 = object1[key];
      const value2 = object2[key];
      if (!(value1 === value2)) {
        return false;
      }
    }
    return true;
  }

  const initDataChange = () => {
    const existingobj = formData.data[currentrow - 1];
    const newobject = JSON.parse('{}');
    const columntypes = formData.pagemeta.columntypes;
    Object.keys(columntypes).filter(datakey => (columntypes[datakey].columnDisplayType === 'key') ||
          (datakey.endsWith("_hidden"))).map((datakey, j) => {
            newobject[datakey]=existingobj[datakey];
          });
    return newobject;      
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value, id } = e.target;
    console.log(e.target.name + " Name " + name + " Value " + value + " id is " + id + "current row " + currentrow);
    const existingobj = formData.data[currentrow - 1];
    const columntypes = formData.pagemeta.columntypes;
    const columndisplaytype = formData.pagemeta.columntypes[id].columnDisplayType;
    let newvalue = value;

    if (columndisplaytype === 'checkbox') {
      let currentvalue = existingobj[id];
      if (dataChanges.hasOwnProperty(id)) {
        currentvalue = dataChanges[id];
      }
      if (currentvalue.toLowerCase() === 'y') {
        newvalue = 'N';
      } else {
        newvalue = 'Y'
      }
    }
    //console.log("Existing Object: "+JSON.stringify(existingobj));

    if (isEmptyObj(dataChanges)) {
      let newobject = initDataChange();
      console.log("New Object: " + JSON.stringify(newobject));
      newobject[id] = newvalue;
      setDataChanges(newobject);
    } else {
      setDataChanges((prevData) => ({ ...prevData, [id]: newvalue }));
    }
    console.log(JSON.stringify(dataChanges));

  }
  const handleStreetChange = (newvalue: string) => {
    if (streetRef.current !== null) {
      const existingobj = formData.data[currentrow - 1];
      if (isEmptyObj(dataChanges)) {
        let newobject = initDataChange(); 
        console.log("New Object: " + JSON.stringify(newobject));
        newobject[streetRef.current.id] = newvalue;
        setDataChanges(newobject);
      } else {
        let newobject = { [streetRef.current.id]: newvalue }
        setDataChanges((prevData) => ({ ...prevData, ...newobject }));
      }
    }
  }
  const handleAddressChange = (components: AddressComponents) => {
    console.log("Handle Address Change is called with " + JSON.stringify(components));
    let newdataobj = JSON.parse("{}");
    if (isEmptyObj(dataChanges)) {
      newdataobj = initDataChange(); 
      //JSON.parse(JSON.stringify(formData.data[currentrow - 1]));
    } else {
      newdataobj = ({});
    }
    if ((streetRef.current) &&
      (streetRef.current?.value !== components.street)) {
      //cityRef.current.value = components.city;
      newdataobj[streetRef.current.id] = components.street;
      console.log("Set Street to " + components.street);
      //cityRef.current.dispatchEvent(new Event('change', { bubbles: false }));
    }

    if ((cityRef.current) &&
      (cityRef.current?.value !== components.city)) {
      //cityRef.current.value = components.city;
      newdataobj[cityRef.current.id] = components.city;
      console.log("Set City to " + components.city);
      //cityRef.current.dispatchEvent(new Event('change', { bubbles: false }));
    }

    if ((stateRef.current) &&
      (stateRef.current?.value !== components.state)) {
      //stateRef.current.value = components.state;
      newdataobj[stateRef.current.id] = components.state;
      console.log("Set State to " + components.state);
      //stateRef.current.dispatchEvent(new Event('change', { bubbles: false }));
    }

    if ((zipcRef.current) &&
      (zipcRef.current?.value !== components.postalCode)) {
      //zipcRef.current.value = components.postalCode;
      newdataobj[zipcRef.current.id] = components.postalCode;
      console.log("Set City to " + components.postalCode);
      // zipcRef.current.dispatchEvent(new Event('change', { bubbles: true }));
    }

    if ((countryRef.current) &&
      (countryRef.current?.value !== components.country)) {
      countryRef.current.value = components.country;
    }
    if (isEmptyObj(dataChanges)) {
      setDataChanges(newdataobj);
    } else {
      setDataChanges((prevData) => ({ ...prevData, ...newdataobj }));
    }

  }
  useEffect(() => {
    console.log("Changed Data is " + JSON.stringify(dataChanges))
  }, [dataChanges])

  const handleCurrentRow = (differential: number, total: number) => {

    if ((currentrow + differential) > 0 && (currentrow + differential <= total)) {
      if (!isEmptyObj(dataChanges)) {
        if (!window.confirm("Discard Changes?")) {
          return;
        }
      }
      setDataChanges({});
      setCurrentRow(currentrow + differential);
    } else {
      alert("Reached data boundry");
    }
  }

  const fetchData = async () => {
    try {
      console.log("Object Type is " + pagename.pagename);
      if (sessionData == null) {
        console.log("In PageContents - User Not Logged In");
        //changeAuthMode("signin");
        return (<div className="container-fluid">
          User not found in Session Context.<a href="/">Please Login</a>
        </div>)
      } else {
        const params = {
          objecttype: "pageobject", pagename: pagename.pagename,
          customer: sessionData.data["Customer Id"], biller: 1001
        };
        const response = await axios.post<typeof formData[]>(process.env.REACT_APP_API_URI + "/pagedata",
          params); // Replace with your API endpoint
        //console.log(" Total Response "+response);
        const newErrors: Record<string, string> = {};
        let myjsondata = JSON.parse("{}");
        if (response != null) {
          //console.log(" Data Response " + JSON.stringify(response.data));
          myjsondata = JSON.parse(JSON.stringify(response.data));
        }

        if (isEmptyObj(myjsondata) || myjsondata.hasOwnProperty("_notfound")) {
          newErrors.notfound = 'Data Not Found';
          console.log("Setting error to No Data Found");
        } else if (myjsondata.hasOwnProperty("pagemeta")) {
          setFormData(myjsondata);
        } else {
          setFormData({});
        }
        setDataChanges({});
        setCurrentRow(1);
        setLoaded(true);
        setErrors(newErrors);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      const newErrors: Record<string, string> = {};
      newErrors.dataerror = 'Error: ' + error;
      setCurrentRow(1);
      setErrors(newErrors);
      setLoaded(false);
    }
  };

  useEffect(() => {
    // Fetch data using the API service
    fetchData();
  }, [pagename]);


  const fileupload = (files: File[] | null) => {
    if (files == null) {
      return;
    }
    const params = {
      customer: sessionData.data["Customer Id"],
      biller: 1001,
      description: 'File Uploads',
      source: pagename.pagename
    };

    files.map(async (newfile) => {
      let formData = new FormData();
      formData.append('file', newfile);
      formData.append('paraobj', JSON.stringify(params));
      try {
        console.log("Loading...." + newfile.name);
        const response = await axios.post(process.env.REACT_APP_API_URI + "/file/upload", formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        console.log('File uploaded successfully');
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    });
    alert("Total Files to upload: " + files.length)
  }


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isEmptyObj(dataChanges)) {
      alert("No Changes done on this page");
      return;
    }
    if (validateForm()) {
      //onSubmit(formData);
      //console.log('Submitted data:', formData);
      //console.log('Columns List '+JSON.stringify(formData.Status))
      //console.log('Data Values '+JSON.stringify(formData["Established Date"].columnType));
      const entries = Object.entries(dataChanges);
      for (let [key, value] of entries) {
        console.log(key, value);
      }

      // Make API call to update data
      const params = {
        objecttype: "pageobject", pagename: pagename.pagename,
        customer: sessionData.data["Customer Id"], biller: 1001,
        changeload: dataChanges
      };
      axios.post(process.env.REACT_APP_API_URI + "/updatedata", params)
        .then(response => {
          console.log('Data updated successfully!', response.data);
          fetchData();
          // Perform any additional actions after successful update
        })
        .catch(error => {
          console.error('Error updating data:', error);
          // Handle error scenarios
        });

    }
  };

  const handleReset = (e: React.FormEvent) => {
    alert("Handling Reset");
    const entries = Object.entries(dataChanges);
    for (let [key, value] of entries) {
      console.log(key, value);
    }
    setDataChanges({});
    setLoaded(false);
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};

    // Perform validation logic for each field
    // Set error message for fields that fail validation
    // Example: Required field validation
    /*
    if (!formData.name) {
      newErrors.name = 'Name is required';
    }
*/
    // Add more validation rules as needed

    setErrors(newErrors);

    // Return true if the form is valid, false otherwise
    return Object.keys(newErrors).length === 0;
  };

  if (Object.keys(errors).length > 0) {

    return (
      <span>
        <AlertModal alerttitle={"Error"} msgobj={JSON.stringify(errors)} />
        {/*
          Object.keys(errors).map((errorkey, i) => {
            return (<h1 key={i}>{errors[errorkey]}</h1>)
          }
          )
          */
        }
      </span>
    );
  } else if (formData.hasOwnProperty("pagemeta") &&
    (Object.keys(formData.pagemeta.columntypes).length > 0)) {
    let columnTypes = formData.pagemeta.columntypes;
    let sortedKeys = sortObjectKeys(formData.pagemeta.columntypes);
    console.log("Curent Row is set to " + currentrow);
    let rowdata = isEmptyObj(dataChanges) ? formData.data[currentrow - 1] : {...formData.data[currentrow-1], ...dataChanges};
    let r = 1;
    return (
      <form className="row g-3 border border-primary" id={pagename + '_form'} onSubmit={handleSubmit} onReset={handleReset}>
        {
          //Object.keys(rowdata).filter(datakey => (datakey !== 'rownumber_meta') &&
          sortedKeys.filter(datakey => (datakey !== 'rownumber_meta') &&
            (!datakey.endsWith("_hidden"))).map((datakey, j) => {
              console.log("In A- Data Key is [" + datakey + "] value is " + rowdata[datakey] + " Display Type is " + columnTypes[datakey]?.columnDisplayType);

              if (datakey.endsWith("_divider")) {
                return (
                  <div key={r++} className="p-1 bg-secondary w-100"></div>
                );
              }

              let columnObj = columnTypes[datakey];
              switch (columnObj.columnDisplayType) {
                case null:
                case "meta":
                case "key":
                  return (
                    <input key={r++} type="hidden" id={datakey} name={columnObj.columnName} defaultValue={rowdata[datakey]} />
                  );
                case "docupload":
                  return (<div key={r++} className="col-lg-6"><FileUpload onUpload={fileupload} maxFileSize={1048576} maxFiles={2} startkey={1000} /></div>);
                case "doclist":
                  const listvalues = rowdata[datakey].split("~");
                  return (
                    <div key={r++} className="col-lg-6">
                      <ol className="list-group list-group-numbered">
                        {
                          listvalues.map((option: string, idx: any) => (
                            <li key={r++} className="list-group-item d-flex justify-content-between align-items-start row">
                              <div className="ms-2 me-auto">
                              {(() => {
                                const optionvalues = option.split('&');
                                console.log('option '+option+' split values '+optionvalues);
                                console.log('First '+optionvalues[0]);
                                console.log('Second '+optionvalues[1]);
                                console.log('Third '+optionvalues[2]);
                                const optionComponents = [];
                                optionComponents.push(<div className="fw-bold">{optionvalues[1]}</div>)
                                if (optionvalues[1].toLowerCase().endsWith(".docx")) {
                                  optionComponents.push(<img src={docimage}  className="img-thumbnail" style={{ width: 100 }} alt="No Document" />)
                                } else if (optionvalues[1].toLowerCase().endsWith(".pdf")) {
                                  optionComponents.push(<img src={pdfimage}  className="img-thumbnail" style={{ width: 100 }} alt="No Document" />)
                                } else {
                                  optionComponents.push(<img src={process.env.REACT_APP_API_URI + "/file/tnimage?documentid=" + optionvalues[0]}  className="img-thumbnail" style={{ width: 100 }} alt="No Document" />)
                                }
                                return optionComponents;
                              })()}
                              </div>
                            </li>
                          ))
                        }
                      </ol>
                    </div>
                  )
                case "display":
                  return (
                    <div key={r++} className="col-lg-6">
                      <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                      <input key={r++} readOnly type="text" id={datakey} name={columnObj.columnName} className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                    </div>
                  );
                case "checkbox":
                  return (
                    <div key={r++} className="col-lg-6">
                      <label key={r++} className="form-check-label" htmlFor={datakey}> {datakey} </label>
                      <input key={r++} id={datakey} name={columnObj.columName} className="form-check mt-2" onChange={handleChange} type="checkbox" value={rowdata[datakey]} checked={rowdata[datakey].toLowerCase() === 'y'} />
                    </div>
                  );
                case "radiobutton":
                  return (
                    <div key={r++} className="col-lg-6">
                      <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                      <input key={r++} type="text" id={datakey} name={columnObj.columnName} className="form-control" />
                    </div>
                  )
                case "date":
                  return (
                    <div key={r++} className="col-lg-6">
                      <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                      <input key={r++} type="date" id={datakey} name={columnObj.columnName} className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                    </div>
                  )
                case "selectlist":
                  return (
                    <div key={r++} className="col-lg-6">
                      <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                      <select key={r++} id={datakey} name={columnObj.columnName} className="form-select" aria-label="Status" onChange={handleChange} value={rowdata[datakey]} >
                        {
                          columnObj.columnListofValues.map((option: any, idx: any) => (
                            <option key={r++} value={option.optionvalue}>{option.optionlabel}</option>
                          ))
                        }
                      </select>
                    </div>
                  );
                case "Customer Status":
                  return (
                    <div key={r++} className="col-lg-6">
                      <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                      <select key={r++} id={datakey} name={columnObj.columnName} className="form-select" aria-label="Status" onChange={handleChange} value={rowdata[datakey]} >
                        {
                          columnObj.columnListofValues.map((option: any, idx: any) => (
                            <option key={r++} value={option.optionvalue}>{option.optionlabel}</option>
                          ))
                        }
                      </select>
                    </div>
                  );
                case "addauto":
                  return (
                    <AddressInput inputRef={streetRef}
                      inputId={datakey}
                      inputLabel={datakey}
                      currentvalue={rowdata[datakey]}
                      handleUpdate={handleAddressChange}
                      handleStreetChange={handleStreetChange} />
                  )
                case "addcity":
                  return (
                    <div key={r++} className="col-lg-6">
                      <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                      <input key={r++} ref={cityRef} type="text" id={datakey} name={columnObj.columnName} className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                    </div>
                  )
                case "addstate":
                  return (
                    <div key={r++} className="col-lg-6">
                      <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                      <input key={r++} ref={stateRef} type="text" id={datakey} name={columnObj.columnName} className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                    </div>
                  )
                case "addzipc":
                  return (
                    <div key={r++} className="col-lg-6">
                      <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                      <input key={r++} ref={zipcRef} type="text" id={datakey} name={columnObj.columnName} className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                    </div>
                  )
                case "addcountry":
                  return (
                    <div key={r++} className="col-lg-6">
                      <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                      <input key={r++} ref={countryRef} type="text" id={datakey} name={columnObj.columnName} className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                    </div>
                  )
                default:
                  if (columnObj.columnDisplayType.startsWith("list")) {
                    return (
                      <div key={r++} className="col-lg-6">
                        <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                        <select key={r++} id={datakey} name={columnObj.columnName} className="form-select" aria-label="Status" onChange={handleChange} value={rowdata[datakey]} >
                          {
                            columnObj.columnListofValues.map((option: any, idx: any) => (
                              <option key={r++} value={option.optionvalue}>{option.optionlabel}</option>
                            ))
                          }
                        </select>
                      </div>
                    )
                  } else {
                    return (
                      <div key={r++} className="col-lg-6">
                        <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                        <input key={r++} type="text" id={datakey} name={columnObj.columnName} className="form-control" onChange={handleChange} value={rowdata[datakey]} />
                      </div>
                    )
                  }
              }


              /*
                        if (!datakey.trim().endsWith("_meta")) {
                          if (datakey.endsWith("_chkbx")) {
                            let newkey = (datakey + '').substring(0, (datakey + '').length - 6);
            
                            return (
                              <div key={r++} className="col-md-6">
                                <label key={r++} className="form-check-label" htmlFor={datakey}> {newkey} </label>
                                <input key={r++} name={datakey} className="form-check mt-2" onChange={handleChange} type="checkbox" value="" id={datakey} />
                              </div>
                            );
                          } else if (datakey === "Status") {
                            return (
                              <div key={r++} className="col-md-6">
                                <label key={r++} htmlFor={"input-" + datakey} className="form-label">{datakey}</label>
                                <select key={r++} id={"input-" + datakey} name={"input-" + datakey} className="form-select" aria-label="Status" value={formData.columntypes[datakey].columnValueStr} >
                                  <option value='A'>Active</option>
                                  <option value='P'>Pending</option>
                                  <option value='C'>Completed</option>
                                  <option value="S">Suspended</option>
                                  <option value='X'>Cancelled</option>
                                  <option value="I">Inactive</option>
                                </select>
                              </div>
                            )
                          } else if (datakey.endsWith("_list")) {
                            let newkey = (datakey + '').substring(0, (datakey + '').length - 5);
                            if (newkey === "Association") {
                              return (
                                <div key={r++} className="col-md-6">
                                  <label key={r++} htmlFor={"input-" + datakey} className="form-label">{newkey}</label>
                                  <select key={r++} id={"input-" + datakey} name={"input-" + datakey} className="form-select" aria-label="Status" value={formData.columntypes[datakey].columnValueStr} >
                                    <option value='C'>Customer</option>
                                    <option value='P'>Service Location</option>
                                    <option value='A'>Account</option>
                                    <option value='O'>One-Time</option>
                                  </select>
                                </div>
                              )
                            } else {
                              return (
                                <div key={r++} className="col-md-6">
                                  <label key={r++} htmlFor={"input-" + datakey} className="form-label">{newkey}</label>
                                  <select key={r++} className="form-select" aria-label="Status" >
                                    <option value='P'>Pending</option>
                                    <option value='A'>Active</option>
                                    <option value='C'>Completed</option>
                                    <option value="S">Suspended</option>
                                    <option value='X'>Cancelled</option>
                                    <option value="I">Inactive</option>
                                  </select>
                                </div>
                              )
                            }
                          } else if (datakey.endsWith("_rb")) {
                            let newkey = (datakey + '').substring(0, (datakey + '').length - 3);
                            return (
                              <div key={r++} className="col-md-6">
                                <label key={r++} htmlFor={"input-" + datakey} className="form-label">{newkey}</label>
                                <input key={r++} type="text" name={datakey} className="form-control" id={"input-" + datakey} />
                              </div>
                            )
                          } else if (datakey.endsWith("_disp")) {
                            let newkey = (datakey + '').substring(0, (datakey + '').length - 5);
                            return (
                              <div key={r++} className="col-md-6">
                                <label key={r++} htmlFor={"input-" + datakey} className="form-label">{newkey}</label>
                                <input key={r++} type="text" name={datakey} className="form-control" id={"input-" + datakey} value={formData.columntypes[datakey].columnValueStr} readOnly />
                              </div>
                            )
                          } else {
                            return (
                              <div key={r++} className="col-md-6">
                                <label key={r++} htmlFor={"input-" + datakey} className="form-label">{datakey}</label>
                                <input key={r++} type="text" name={datakey} onChange={handleChange} className="form-control" id={"input-" + datakey} value={formData.columntypes[datakey].columnValueStr} />
                              </div>
                            )
                          }
                        } else {
                          return '';
                        }
                      */

            }

            )}
        <div className="row mt-2">
          {
            (Number(rowdata._total_rows) <= 1) &&
            <div className="col-6">
              <span className='d-none'></span>
            </div>
          }
          {
            (Number(rowdata._total_rows) > 1) &&
            <div className="col-6 d-flex justify-content-start">
              <button type="button" className="btn btn-success me-2" onClick={() => handleCurrentRow(-1, Number(rowdata._total_rows))}><i className="bi bi-box-arrow-left"></i></button>
              <span className="text-info text-center">{currentrow} of {rowdata._total_rows} </span>
              <button type="button" className="btn btn-warning ml-2" onClick={() => handleCurrentRow(1, Number(rowdata._total_rows))}><i className="bi bi-box-arrow-right"></i></button>
            </div>
          }

          <div className="col-6 d-flex justify-content-end">
            <button type="submit" className="btn btn-success me-2">Save</button>
            <button type="reset" className="btn btn-warning">Reset</button>
          </div>
        </div>
      </form >
    )

  } else if (isLoaded) {
    return (
      <span>
        <h1> Not MetaData Values Found!</h1>
      </span>
    )
  } else {
    return (
      <div className="text-center">
        <div className="spinner-grow text-success" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-danger" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-warning" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-info" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

}

export default DataForm;
