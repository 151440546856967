import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './views/Home';
import './App.css';
import './css/biller.css';
import AppContextProvider from './context/AppContextProvider';
import { LoadScript } from '@react-google-maps/api';

function App() {
 return (
  <LoadScript 
  googleMapsApiKey="AIzaSyAuIJ-FawUSuEhRD1YsLr5JuNKQYB0Rh6k"
  libraries= {['places']}
  >
    <AppContextProvider>
       <Router>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/profile" element={<Home />}/>
      </Routes>
    </Router>
    </AppContextProvider>
  </LoadScript>
  );
}

export default App;
