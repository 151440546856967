import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

interface AddressComponents {
  street: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}
interface AddressChange {
  inputRef: React.RefObject<HTMLInputElement>;
  inputLabel: string;
  inputId: string;
  currentvalue: string;
  handleUpdate: (components: AddressComponents) => void;
  handleStreetChange: (value: string) => void;
}

const AddressInput: React.FC<AddressChange> = ({inputRef, inputLabel, inputId, currentvalue, handleUpdate, handleStreetChange}) => {
  const [street, setStreet] = useState<string>(currentvalue);
  let newkey:number = 5000;
  const handleChange = (value: string) => {
    console.log(value);
    setStreet(value);
    if (inputRef.current !== null) {
      handleStreetChange(value)
    }
  };

  const handleSelect = async (selectedAddress: string) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);

      console.log('Selected address:', selectedAddress);
      console.log('Latitude:', latLng.lat);
      console.log('Longitude:', latLng.lng);

      const components = results[0].address_components;

      const updatedAddressComponents: AddressComponents = {
        street: getComponentValue(components, 'street_number') + ' ' + getComponentValue(components, 'route'),
        city: getComponentValue(components, 'locality'),
        state: getComponentValue(components, 'administrative_area_level_1'),
        postalCode: getComponentValue(components, 'postal_code')+getComponentValue(components, "zip_code"),
        country: getComponentValue(components, 'country'),
      };
      console.log("Before "+JSON.stringify(updatedAddressComponents));
      handleUpdate(updatedAddressComponents);
      handleChange(updatedAddressComponents.street);
      //setStreet(updatedAddressComponents.street);
     // AddressChange(addressComponents);
    } catch (error) {
      console.error('Error retrieving address components:', error);
    }
    
  };
  const getComponentValue = (components: google.maps.GeocoderAddressComponent[], type: string) => {
    const component = components.find((comp) => comp.types.includes(type));
    let longname: boolean = false;
    switch (type) {
      case "street_number": 
      case "city":
      case "postal_code":
      case "locality":
          longname = true;
    }
    return component ? (longname?component.long_name: component.short_name) : '';
  };

  return (
      <PlacesAutocomplete 
      value={street}   onSelect={handleSelect} onChange={handleChange}
      searchOptions={{ componentRestrictions: { country: ['ca', 'us'] } }}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="col-lg-6">
            <label htmlFor={inputId} className="form-label">{inputLabel}</label>
            <input ref={inputRef} id={inputId} className="form-control"   {...getInputProps({ placeholder: 'Enter address' })} />
            <div className="border border-info">
              {loading ? <div>Loading...</div> : null}
              {
      
              suggestions.map((suggestion, index) => {
                const style = {
                  backgroundColor: suggestion.active ? '#0a0a0a' : '#ffffff',
                };
                return (
                  <div {...getSuggestionItemProps(suggestion, { style })} >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
  );
};

export default AddressInput;
