import React, { useState } from 'react';
import {Button, Modal} from 'react-bootstrap';

interface DataProps {
  alerttitle : string;
  msgobj: string;
}
const AlertModal: React.FC<DataProps> = (props) => {
  const [showModal, setShowModal] = useState(true);
  console.log(props.msgobj+" "+props.alerttitle);
  const msgjson = JSON.parse(props.msgobj);
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <Button onClick={openModal}>Show {props.alerttitle}</Button>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{props.alerttitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{Object.keys(msgjson).map((key, ind) => {
            return (msgjson[key]+"\n");
          })}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AlertModal;
