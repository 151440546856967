import  { createContext } from 'react';

interface AppContextProps {
  // Define your context properties and functions here
  sessionData: any | undefined;
  appVersion: string;
  setSessionData: (data: any) => void;
}

const AppContext = createContext<AppContextProps>({
  sessionData: undefined,
  appVersion: '',
  setSessionData:  () => {}
});

export default AppContext;
