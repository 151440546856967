import React, { useContext, useState } from "react";
import AppContext from "../context/AppContext";
import logo from '../img/Transparent-NoLine2.png';
import arcticlogo from '../img/nologo.png';


interface LoginProps {
    currentstep: string;
    changeAuthMode: (newmode: string) => void;
  }

const Login :React.FC<LoginProps> = ({currentstep, changeAuthMode}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
  
    const { sessionData, appVersion, setSessionData } = useContext(AppContext);

    console.log('Session Data is '+sessionData+' App version is '+appVersion);

    if ((sessionData != null) || (sessionData !== undefined)) {
      changeAuthMode("loggedin");
    }

    console.log("Change Auth Mode Value is "+changeAuthMode+" type of "+typeof changeAuthMode);
    const handleUsernameChange = (e : any) => {
      setUsername(e.target.value);
    };
  
    const handlePasswordChange = (e : any) => {
      setPassword(e.target.value);
    };
  
    const handleSubmit = (e : any) => {
  //      alert('Handling Submit '+process.env.REACT_APP_API_URI);
      e.preventDefault();
   //   alert('Handling Submit After'+ JSON.stringify({username, password}));
      // Make a POST request to the login endpoint
      fetch(process.env.REACT_APP_API_URI+'/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      })
        .then(response => response.json())
        .then(data => {
          // Handle the response from the server
          console.log(data); // You can customize this based on your API response
          setSessionData(data);
          console.log("Session Context Data is set to "+JSON.stringify(data));
          changeAuthMode("loggedin");
        })
        .catch(error => console.error(error));
    };
   
    return (
        <div className="container">
        <div className="row">
           <div className="col-md-6 offset-md-3">
              <div className="card my-5">
                 <div className="card-header">
                    <h4 className="text-center text-dark mt-5">Log In to Your Account</h4>
                 </div>
                 <form className="card-body cardbody-color p-lg-5" onSubmit={handleSubmit}>
                    <div className="text-center">
                       <img src={arcticlogo} className="img-fluid profile-image-pic img-thumbnail rounded-circle my-2"
                          alt="Logo" />
                    </div>
                    <div className="mb-3">
                       <input type="text" id="username" className="form-control" value={username} onChange={handleUsernameChange} placeholder="User Name" />
                    </div>
                    <div className="mb-3">
                       <input type="password" className="form-control" id="password" value={password} onChange={handlePasswordChange} placeholder="password"/>
                    </div>
                    <div className="text-center">
                       <button type="submit" className="btn btn-primary px-5 mb-5 w-100">Login</button></div>
                    <p className="text-center mt-2">
                       Forgot <a href="/" onClick={(e) => {e.preventDefault(); changeAuthMode("forgotpassword")}} >password?</a>
                    </p>
                    <div id="emailHelp" className="form-text text-center mb-5 text-dark">Need
                       to Register? <a href="/" onClick={(e) => {e.preventDefault(); changeAuthMode("signup")}} className="text-dark fw-bold"> Sign Up</a>
                    </div>
                 </form>
                 <div className="card-footer">
                    <div className="text-center">
                       <div className="text-primary">Powered By &nbsp;
                          <img src={logo} width="100px" className="img-fluid profile-image-pic img-thumbnail"
                             alt="Logo" />
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
    );
  };
  
  export default Login;
  