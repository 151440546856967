import React, { useMemo, useState } from 'react';
import AppContext from './AppContext';

const AppContextProvider: React.FC<any> = ({ children }) => {
  // Initialize your context values here
  const [sessionData, setSessionData] = useState<any>(undefined);
  const appVersion = '1.0.0';

  // Memorize the context value to prevent re-initialization on each render
  const appContextValue = useMemo(
    () => ({
      sessionData,
      appVersion,
      setSessionData
    }),
    [sessionData, appVersion]
  );

  return (
    <AppContext.Provider value={appContextValue}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
