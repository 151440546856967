import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../context/AppContext';
import axios from 'axios';
import AlertModal from './AlertModal';
import MyPDFTemplate from './MyPDFTemplate';
import PDFModalViewer from './PDFModalViewer';
import { pdf } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';

type Master = {
  id: number;
  name: string;
};

type Detail = {
  id: number;
  description: string;
};

interface DataProps {
  master: string;
  detail: string;
}

const MasterDetailForm: React.FC<DataProps> = (pagename) => {
  const [masters, setMasters] = useState<Record<string, any>>({});
  const [details, setDetails] = useState<Record<string, any>>({});
  const [activeBill, setActiveBill] = useState<string>('');
  const [pdfParam, setPDFParam] = useState<any>(null);
  const { sessionData } = useContext(AppContext);
  const [errors, setErrors] = useState<Record<string, string>>({});

  console.log("In Master Details Form - Master Page: " + pagename.master + " Details Page: " + pagename.detail);

  function isEmptyObj(obj: Record<string, any> | null): boolean {
    return (obj == null) || (Object.keys(obj).length === 0 && obj.constructor === Object);
  }

  useEffect(() => {
    console.log("Before Fetch Masters");
    fetchMasters();
  }, [pagename]);

  useEffect(() => {
    console.log("Before Fetch Details");
    if (!isEmptyObj(masters)) {
      let firstdetailid = masters.data[0]['Bill Id'];
      if ((firstdetailid > 0) && (isEmptyObj(details))) {
        fetchDetails(firstdetailid);
      }
    }
  }, [masters]);

  

  const fetchMasters = async () => {
    console.log("In Fetch Masters");
    if (sessionData == null) {
      console.log("In PageContents - User Not Logged In");
      //changeAuthMode("signin");
      return (<div className="container-fluid">
        User not found in Session Context.<a href="/">Please Login</a>
      </div>)
    } else {
      try {
        console.log("Before Setting Parameters");
        const params = {
          objecttype: "pageobject",
          pagename: pagename.master,
          customer: sessionData.data["Customer Id"],
          biller: 1001
        };
        console.log("Before Fetching Master " + JSON.stringify(params));
        const response = await axios.post<typeof masters[]>(process.env.REACT_APP_API_URI + "/pagedata",
          params); // Replace with your API endpoint
        //console.log(" Total Response "+response);
        const newErrors: Record<string, string> = {}
        let myjsondata = JSON.parse('{}');
        if (response != null) {
          console.log(" Master Data Response " + JSON.stringify(response.data));
          myjsondata = JSON.parse(JSON.stringify(response.data));
         // setActiveBill(myjsondata.data[0]['Reference Number']);
        } else {
          console.log("Master Response is NULL");
        }

        if (isEmptyObj(myjsondata) || myjsondata.hasOwnProperty("_notfound")) {
          newErrors.notfound = 'Data Not Found';
          console.log("Setting error to No Data Found");
        } else if (myjsondata.hasOwnProperty("pagemeta")) {
          setMasters(myjsondata);
        } else {
          setMasters({});
        }
        setErrors(newErrors);

      } catch (error) {
        console.error('Error fetching data:', error);
        const newErrors: Record<string, string> = {};
        newErrors.dataerror = 'Error: ' + error;
        setErrors(newErrors);
      }
    };

  };

  function renderMaster() {

    const masterList: any[] = [];
    masterList.push(<button className="list-group-item active" key='0' >
                    <div className="row">
                    <div className="col">Reference Number</div>
                    <div className="col">Bill Date</div>
                    <div className="col">Amount Due</div>
                    <div className="col">Due Date</div>
                    </div>
                     </button>);
    for(let i = 0; i < masters.data.length; i++) {
        masterList.push(<button className={(masters.data[i]['Reference Number']===activeBill)?
                      "list-group-item list-group-item-action list-group-item-info":
                      "list-group-item list-group-item-action"} key={masters.data[i]['Bill Id']} onClick={() => fetchDetails(masters.data[i]['Bill Id'])}>
                    <div className="row text-center">
                    <div className="col">{masters.data[i]['Reference Number']}</div>
                    <div className="col">{masters.data[i]['Bill Date']}</div>
                    <div className="col">{masters.data[i]['Amount Due']}</div>
                    <div className="col">{masters.data[i]['Due Date']}</div>
                      </div>
                     </button>);
    }
    return masterList;

}


  const fetchDetails = async (historyId: number) => {
    try {
      const params = {
        objecttype: "pagedetail",
        pagename: pagename.detail,
        customer: sessionData.data["Customer Id"],
        biller: 1001,
        detail: historyId
      };
     // alert("Fetching Details for "+historyId);
      console.log("Fetching Details " + JSON.stringify(params));
      const response = await axios.post<typeof masters[]>(process.env.REACT_APP_API_URI + "/pagedata",
        params); // Replace with your API endpoint
      //console.log(" Total Response "+response);
      const newErrors: Record<string, string> = {};
      let myjsondata = JSON.parse('{}');
      if (response != null) {
        console.log("Detail Data Response " + JSON.stringify(response.data));
        myjsondata = JSON.parse(JSON.stringify(response.data));
        setActiveBill(myjsondata.data[0]['Reference Number']);
        const params = {title:"Bill Number:  "+myjsondata.data[0]['Reference Number'],
                      description:JSON.stringify(myjsondata.data[0])}
        setPDFParam(params);
      }

      if (isEmptyObj(myjsondata) || myjsondata.hasOwnProperty("_notfound")) {
        newErrors.notfound = 'Data Not Found';
        console.log("Detail Setting error to No Data Found");
      } else if (myjsondata.hasOwnProperty("pagemeta")) {
        setDetails(myjsondata);
      } else {
        setDetails({});
      }
      setErrors(newErrors);

    } catch (error) {
      console.error('Detail Error fetching data:', error);
      const newErrors: Record<string, string> = {};
      newErrors.dataerror = 'Error: ' + error;
      setErrors(newErrors);
    }
  };

  function renderDetails() {
    
    if (isEmptyObj(details)) {
      return 'Loading...';
    }
    let rowdata = details.data[0];
    let columnTypes = details.pagemeta.columntypes;
    let r= 1000;
    function showPDF(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
      alert('Show PDF File for Bill Reference: '+event.currentTarget.id);
      const params = {title:"This is Bill "+event.currentTarget.id,
                      description:"Thse are bill details with Amount Due and Due Date"}
      setPDFParam(params);
      alert("AFter Setting pdfParam");
      
    }
    return (
      <div>
      {(pdfParam) && (<PDFModalViewer pdftitle={"Bill View - "} msgobj={pdfParam.description} clearParam={setPDFParam} />)}

      <form className="row g-3 border border-primary mt-2" id={pagename + '_form'} >
       {
         Object.keys(rowdata).filter(datakey => (datakey !== 'rownumber_meta') &&
           (!datakey.endsWith("_hidden"))).map((datakey, j) => {
             console.log("In A- Data Key is [" + datakey + "] value is " + rowdata[datakey] + " Display Type is " + columnTypes[datakey]?.columnDisplayType);

             if (datakey.endsWith("_divider")) {
               return (
                 <div key={r++} className="p-1 bg-secondary w-100"></div>
               );
             }

             let columnObj = columnTypes[datakey];
             switch (columnObj.columnDisplayType) {
               case null:
               case "meta":
               case "key":
                 return (
                   <input key={r++} type="hidden" id={datakey} name={columnObj.columnName} defaultValue={rowdata[datakey]} />
                 );
               case "listbillhist":
               return (
                 <div key={r++} className="col-lg-3">
                   <label key={r++} htmlFor={datakey} className="form-label">{columnObj.columnListTitle}</label>
                   <ol className="list-group">
                   <li key={r++} className="list-group-item d-flex justify-content-between align-items-start row">
                     <div id={datakey} className="ms-2 me-auto">
                       <div className="row d-flex">
                       <div className="fw-bold col">Reference</div>
                       <div className="fw-bold col">Bill Date</div>
                       <div className="fw-bold col">Due Date</div>
                       <div className="fw-bold col">Amount Due</div>
                       </div>
                     </div>
                     </li>
                   {
                       columnObj.columnListofValues.map((option: any, idx: any) => (
                         <li key={r++} className="list-group-item d-flex justify-content-between align-items-start row">
                            <div id={datakey} className="ms-2 me-auto">
                             <div className="row d-flex">
                            {(() => {
                               const optionvalues = option.optionlabel.split('|');
                               console.log('option '+option+' split values '+optionvalues);
                               console.log('First '+optionvalues[0]);
                               console.log('Second '+optionvalues[1]);
                               console.log('Third '+optionvalues[2]);
                               const optionComponents = [];
                               optionComponents.push(<div className="fw-bold col">{optionvalues[0]}</div>)
                               optionComponents.push(<div className="fw-bold col">{optionvalues[1]}</div>)
                               optionComponents.push(<div className="fw-bold col ">{optionvalues[2]}</div>)
                               optionComponents.push(<div className="fw-bold col ">{optionvalues[3]}</div>)
                               return optionComponents;
                             })()}
                             </div>
                             </div>
                           </li>
                       ))
                   }
                   </ol>
                 </div>
                 )
               case "display":
                 return (
                   <div key={r++} className="col-lg-6">
                     <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                     <input key={r++} readOnly type="text" id={datakey} name={columnObj.columnName} className="form-control"  value={rowdata[datakey]} />
                   </div>
                 );
               case "checkbox":
                 return (
                   <div key={r++} className="col-lg-6">
                     <label key={r++} className="form-check-label" htmlFor={datakey}> {datakey} </label>
                     <input readOnly key={r++} id={datakey} name={columnObj.columName} className="form-check mt-2" type="checkbox" value={rowdata[datakey]} checked={rowdata[datakey].toLowerCase() === 'y'} />
                   </div>
                 );
               case "radiobutton":
                 return (
                   <div key={r++} className="col-lg-6">
                     <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                     <input readOnly key={r++} type="text" id={datakey} name={columnObj.columnName} className="form-control" />
                   </div>
                 )
               case "date":
                 return (
                   <div key={r++} className="col-lg-6">
                     <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                     <input readOnly key={r++} type="date" id={datakey} name={columnObj.columnName} className="form-control"  />
                   </div>
                 )
               case "selectlist":
                 return (
                   <div key={r++} className="col-lg-6">
                     <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                     <select disabled key={r++} id={datakey} name={columnObj.columnName} className="form-select" aria-label="Status" value={rowdata[datakey]} >
                       {
                         columnObj.columnListofValues.map((option: any, idx: any) => (
                           <option key={r++} value={option.optionvalue}>{option.optionlabel}</option>
                         ))
                       }
                     </select>
                   </div>
                 );
               case "Customer Status":
                 return (
                   <div key={r++} className="col-lg-6">
                     <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                     <select disabled key={r++} id={datakey} name={columnObj.columnName} className="form-select" aria-label="Status" value={rowdata[datakey]} >
                       {
                         columnObj.columnListofValues.map((option: any, idx: any) => (
                           <option key={r++} value={option.optionvalue}>{option.optionlabel}</option>
                         ))
                       }
                     </select>
                   </div>
                 );
               default:
                 if (columnObj.columnDisplayType.startsWith("list")) {
                   return (
                     <div key={r++} className="col-lg-6">
                       <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                       <select key={r++} id={datakey} name={columnObj.columnName} className="form-select" aria-label="Status" disabled value={rowdata[datakey]} >
                         {
                           columnObj.columnListofValues.map((option: any, idx: any) => (
                             <option key={r++} value={option.optionvalue}>{option.optionlabel}</option>
                           ))
                         }
                       </select>
                     </div>
                   )
                 } else {
                   return (
                     <div key={r++} className="col-lg-6">
                       <label key={r++} htmlFor={datakey} className="form-label">{datakey}</label>
                       <input key={r++} type="text" id={datakey} name={columnObj.columnName} className="form-control" readOnly value={rowdata[datakey]} />
                     </div>
                   )
                 }
             }

           }

           )}
       <div className="row mt-2">
         {
           (Number(rowdata._total_rows) <= 1) &&
           <div className="col-6">
             <span className='d-none'></span>
           </div>
         }
         <div className="col-6 d-flex justify-content-end">
           {(pdfParam) && (<button type="button" id={rowdata['Reference Number']} className="btn btn-success mb-2" data-bs-toggle="modal" data-bs-target="#myModal">Show PDF</button>)}
         </div>
       </div>
     </form >
     </div>
   )

}
  if (Object.keys(errors).length > 0) {

    return (
      <span>
        <AlertModal alerttitle={"Error"} msgobj={JSON.stringify(errors)} />
        {/*
          Object.keys(errors).map((errorkey, i) => {
            return (<h1 key={i}>{errors[errorkey]}</h1>)
          }
          )
          */
        }
      </span>
    );
  } else if (masters.hasOwnProperty("pagemeta") &&
    (Object.keys(masters.pagemeta.columntypes).length > 0)) {
    return (
      <div className="row">
        <div className="container-lg col-xl-5">
        <h2>Bill History</h2>
        <div className="list-group">
          {renderMaster()}
        </div>
        </div>
        <div className="container-lg col-xl-7">
        <h2>Bill Details</h2>
{         renderDetails()
          
          

/*
        <h2>Details</h2>
        <ul>
          {Object.keys(details.data).map((i, idx) => (
            <li key={details.data[i]['Biller Id']}>{details.data[i]['Location']}</li>
          ))
          }
        </ul>
        */
        }
      </div>
      </div>
    );
   } else {
    return (
      <div className="text-center">
        <div className="spinner-grow text-success" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-danger" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-warning" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-grow text-info" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }
}

export default MasterDetailForm;
