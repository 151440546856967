import { useContext, useState } from 'react';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
//import Dashboard from '../components/Dashboard';
import logo from '../img/Transparent-NoLine2.png';
import arcticlogo from '../img/nologo.png';
//import Auth from '../components/Auth';
import Login from '../components/Login';
import PageContents from '../components/PageContents';
import SignupStep from '../components/SignupStep';
import Topbar from '../components/Topbar';
import AppContext from '../context/AppContext';

type NavigationItem = 'profile' | 'properties' | 'programs' | 'billhistory' | 'payments' | 'assets';
function Home(props: any) {
   const { sessionData } = useContext(AppContext);
   console.log('Session Data is ' + sessionData);
   let [authMode, setAuthMode] = useState((sessionData === undefined) || (sessionData == null) ? "signin" : "loggedin");
   let [signupStep, setSignupStep] = useState("rstep1");
   const [selectedNavItem, setSelectedNavItem] = useState<NavigationItem>('profile');

   const changeAuthMode = (authmode: string) => {
      setAuthMode(authmode);
   }
   const changeStep = (stepnumber: string) => {
      console.log("Change step to " + stepnumber);
      setSignupStep(stepnumber);
   }

   if (authMode === "signin") {
      return (
         <Login currentstep={signupStep} changeAuthMode={changeAuthMode} />
      )
   } else if (authMode === "signup") {
      return (
         <div className="container">
            <div className="row">
               <div className="col-md-8 offset-md-2">
                  <div className="card my-5">
                     <div className="card-header">
                        <div className="text-center">
                           <img src={arcticlogo} width="40px" className="img-fluid profile-image-pic img-thumbnail rounded-circle my-2"
                              alt="Logo" />
                           <h3 className="text-center text-dark mt-5">
                              Sign Up for an Account</h3>
                        </div>
                     </div>
                     <div className="card-body">
                        <ul className="nav nav-tabs">
                           <li className="nav-item">
                              <a href="/" className={signupStep === "rstep1" ? "nav-link active" : "nav-link"} onClick={(e) =>{e.preventDefault(); changeStep("rstep1")}}>Signup</a>
                           </li>
                           <li className="nav-item">
                              <a href="/" className={signupStep === "rstep2" ? "nav-link active" : "nav-link"} onClick={(e) => {e.preventDefault(); changeStep("rstep2")}}>Contract</a>
                           </li>
                           <li className="nav-item">
                              <a href="/" className={signupStep === "rstep3" ? "nav-link active" : "nav-link"} onClick={(e) =>{e.preventDefault(); changeStep("rstep3")}}>KYC</a>
                           </li>
                           <li className="nav-item">
                              <a href="/" className={signupStep === "rstep4" ? "nav-link active" : "nav-link"} onClick={(e) =>{e.preventDefault(); changeStep("rstep4")}}>Payment</a>
                           </li>
                           <li className="nav-item">
                              <a href="/" className={signupStep === "rstep5" ? "nav-link active" : "nav-link"} onClick={(e) =>{e.preventDefault(); changeStep("rstep5")}}>Password</a>
                           </li>
                           <li className="nav-item">
                              <a href="/" className={signupStep === "rstep6" ? "nav-link active" : "nav-link"} onClick={(e) =>{e.preventDefault(); changeStep("rstep6")}}>Plan Details</a>
                           </li>
                           <li className="nav-item">
                              <a href="/" className={signupStep === "rstep7" ? "nav-link active" : "nav-link"} onClick={(e) =>{e.preventDefault(); changeStep("rstep7")}}>Summary</a>
                           </li>
                        </ul>
                        <SignupStep currentstep={signupStep} changefunction={changeStep} />

                     </div>
                     <div className="card-footer">
                        <div className="text-center">
                           <div className="text-primary">Powered By&nbsp;
                              <img src={logo} width="100px" className="img-fluid profile-image-pic img-thumbnail"
                                 alt="Logo" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      )
   } else {
      console.log(" auth mode is " + authMode);
      return (
         <>
            <Navigation selectedNavItem={selectedNavItem} setSelectedNavItem={setSelectedNavItem} />
            <div id="content-wrapper" className="d-flex flex-column">
               <div id="content">
                  <Topbar selectedNavItem={selectedNavItem} setSelectedNavItem={setSelectedNavItem}/>
                  <PageContents pagename={selectedNavItem} />
                  <div className="container">Status: Loaded Successfully</div>
               </div>
            </div>
            <Footer />
         </>
      );
   }
}

export default Home;
