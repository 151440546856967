import React, { ChangeEvent, Component, useState } from 'react';
import arcticlogo from '../img/arcticlogo.jpg';
import $ from 'jquery';
import { read } from 'fs';
import { clone } from 'chart.js/dist/helpers/helpers.core';
import FileUpload from './FileUpload';

class SignupStep extends Component<any, any> {
   constructor(props: any) {
      super(props);
      this.state = {
         error: null,
         data: null,
         paymentmethod : "debit"
      };
       this.changePaymentMethod = this.changePaymentMethod.bind(this);
      // this.removeUpload = this.removeUpload.bind(this);
      // console.log("Current Step is "+props.currentstep);
   }

   changePaymentMethod = (newmethod: string) => {
      console.log("Change Payment Method to " + newmethod);
      this.setState({
         ...this.state,
         paymentmethod : newmethod,
       })
       console.log("new state "+JSON.stringify(this.state));
   }

   handleUpload = (files: File[]|null) => {
      // Perform upload logic here
      if (files == null) {
          console.log("No Files to upload");
      } else {
      console.log('Uploading files:', files);
      }
      return null;
    };
   /*
   readURL(input: any) {
      if (input.files && input.files[0]) {
         console.log("Total Files "+input.files.length);
         
         Object.keys(input.files).map((file :any, i : any ) => {
         let reader = new FileReader();
         reader.onload = function (e) {
            $('.image-upload-wrap').hide();
            if (!e.target) {
               alert("Target is null");
               return;
            }
            $('.file-upload-content:last').clone(true).attr('id', input.files[file].name).appendTo('.file-upload');
            $('.file-upload-image:last').attr('src', e.target.result as string);
            $('.file-upload-content:last').show();
            $('.file-upload-content:first').show();

            $('.image-title:last').html(input.files[file].name);
         };
         
            console.log(`file-${i}`, JSON.stringify(input.files[file]), input.files[file].name);
            reader.readAsDataURL(input.files[file]);
         });

      } else {
         this.removeUpload(null);
      }
   }

   removeUpload(event:any|null) {
      //$('.file-upload-input').replaceWith($('.file-upload-input').clone());
      //alert('On Click function called');
      if (event == null) {
         console.log("Null Event Click");
      }
      console.log("Remove Target is "+event.target);
      $(event.target).closest('.file-upload-content').hide();
      //$('.file-upload-content').hide();
      $('.image-upload-wrap').show();
   }

   */

   componentDidMount() {
      /*
      $('.image-upload-wrap').on('dragover', function () {
         $('.image-upload-wrap').addClass('image-dropping');
      });
      $('.image-upload-wrap').on('dragleave', function () {
         $('.image-upload-wrap').removeClass('image-dropping');
      });
      $('.remove-image').on('click', this.removeUpload);
      */
   }

   render() {
      const { error, data, paymentmethod } = this.state;
       // files is not an array, but it's iterable, spread to get an array of files
      var signupStep = this.props.currentstep;
      console.log("Re-Rendering for Step " + signupStep+" payment method is "+paymentmethod);
      if (error) {
         return (<div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
               <div className="container">Error: {error.message}</div>;
            </div>
         </div>);
      } else if (signupStep === "rstep1") {
         console.log("Returning Step 1");
         return (
            <form className="card-body cardbody-color p-lg-5">
               <div className="mb-3">
                  <input type="text" className="form-control" id="customername" aria-describedby="emailHelp"
                     placeholder="Your Name" />
               </div>

               <div className="mb-3">
                  <input type="tel" className="form-control" id="homephone" aria-describedby="emailHelp"
                     placeholder="Home Phone" />
               </div>
               <div className="row mb-3">
                  <div className="col-9">
                     <input type="tel" className="form-control" id="cellphone" aria-describedby="emailHelp"
                        placeholder="Cell Phone" />
                  </div>
                  <div className="col-3">
                     <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="smsCheck" />
                        <label className="form-check-label" htmlFor="smsCheck">
                           Allow SMS
                        </label>
                     </div>
                  </div>
               </div>
               <div className="mb-3">
                  <input type="email" className="form-control" id="customeremail" aria-describedby="emailHelp"
                     placeholder="Your Email" />
               </div>
               <div className="mb-3">
                  <label htmlFor="inputAddress" className="form-label">Address</label>
                  <input type="text" className="form-control" id="inputAddress" placeholder="1234 Main St" />
               </div>
               <div className="mb-3">
                  <label htmlFor="inputAddress2" className="form-label">Address 2</label>
                  <input type="text" className="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor" />
               </div>
               <div className="row">
                  <div className="col-md-6">
                     <label htmlFor="inputCity" className="form-label">City</label>
                     <input type="text" className="form-control" id="inputCity" />
                  </div>
                  <div className="col-md-4">
                     <label htmlFor="inputState" className="form-label">State</label>
                     <select id="inputState" className="form-select">
                        <option selected>Choose...</option>
                        <option>...</option>
                     </select>
                  </div>
                  <div className="col-md-2">
                     <label htmlFor="inputZip" className="form-label">Zip</label>
                     <input type="text" className="form-control" id="inputZip" />
                  </div>
               </div>
               <div className="mb-3">
                  <label htmlFor="inputState" className="form-label">Builder</label>
                  <select id="inputState" className="form-select">
                     <option selected>Choose...</option>
                     <option>...</option>
                  </select>
               </div>
               <div className="mb-3">
                  <label htmlFor="inputLotNum" className="form-label">Lot #</label>
                  <input type="text" className="form-control" id="inputLotNum" placeholder="Lot Number, Apartment, studio, or floor" />
               </div>
               <div className="text-center"><button type="button" onClick={() => this.props.changefunction("rstep2")} className="btn btn-primary px-5 mb-5 w-100">Next</button></div>
               <div id="emailHelp" className="form-text text-center mb-5 text-dark">Already Registered? <a href="/" className="text-dark fw-bold"> Sign In</a>
               </div>
            </form>
         );
      } else if (signupStep === "rstep2") {
         console.log("Returning Step 2");
         return (
            <form className="card-body cardbody-color p-lg-5">
               <div className="card">
                  <div className="card-body h-100 border">
                     <h5 className="card-title">Contract Agreement</h5>
                     <h6 className="card-subtitle mb-2 text-muted">I hereby agree to pay for the program</h6>
                     <p className="card-text h-100 scrollable overflow-auto">

                        A service agreement is a formal agreement between a service provider and a client that covers the terms and conditions of a particular service relationship.
                        A service agreement serves three primary functions:
                        Articulating the expectations of the parties to the agreement;
                        Providing a mechanism for governance and issue resolution;
                        Acting as a scorecard against which to examine performance and results.
                        Service agreements enhance governance, accountability, and service quality by clearly defining roles, responsibilities, processes, and performance expectations. The agreement details what the service provider will do for the client and how that service will be rendered.
                        A service provider is an independent contractor who operates on their own. They aren't an employee of the client, and they are bound only by the terms of this document. Other contracts that may be useful in service provider situations include a Non-Disclosure Agreement.
                        <h6 className="card-subtitle">How to use this document?</h6>
                        This document may be used by a service provider getting ready to enter into a new relationship with a client, or by a client preparing to hire a new service provider.
                        The document can cover pertinent identifying details, such as whether the parties are individuals or businesses, and their respective addresses and contact information. The document can also include the most important characteristics of the agreement between the parties, like duration for the work (e.g., continue indefinitely, end at a certain date, etc.), fee information, details on the services being provided, and more.
                        The document is flexible and contains various input fields and customization fields allowing the parties to tailor the agreement to their specific needs. This Service Agreement will help the parties outline their expectations before the work actually begins.
                        Once the document is signed, each person must keep a copy for their own records.

                        <h6 className="card-subtitle">Applicable Laws</h6>
                        In Canada, the majority of laws governing services agreements is derived from the law of contracts and case law rather than statutes. However, if a service is a professional service, such as accounting, engineering, and so forth, there are strict laws and a code of ethics that govern the service provider. Furthermore, if the service is that of construction or building structures on private lots, permits may be needed. Overall, the legal requirements of any service agreement are contextual and depend on the type of service being provided.

                        How to modify the template?

                        You fill out a form. The document is created before your eyes as you respond to the questions.

                        At the end, you receive it in Word and PDF formats. You can modify it and reuse it.
                        Fill out the template

                        Guides to help you

                        How to Protect your Business' Secrets?

                        What is the Difference between an Employee and an Independent Contractor?

                        Other names for the document: Agreement for Provision of Consultancy Services, Agreement of Consultancy, Agreement of Service, Agreement of Services, Agreement of Supply of Services

                        Country: Canada (English)
                        Sales and Commerce - Other downloadable templates of legal documents

                        Agreement for Online Seminars

                        Promissory Note

                        Agreement for Online Seminars (Covid-19/Coronavirus)

                        General Receipt

                        Merchandise Distribution Agreement

                        Catering Services Agreement

                        Public Personality Performance Contract

                        Marketing Services Agreement

                        Franchise Agreement

                        Sale of Goods Agreement
                     </p>
                     <a href="/" className="card-link">Download link</a>
                     <a href="/" className="card-link">Click here to sign</a>
                  </div>
               </div>
               <div className="text-center"><button type="button" onClick={() => this.props.changefunction("rstep3")} className="btn btn-primary px-5 mb-5 w-100">Next</button></div>
               <div id="emailHelp" className="form-text text-center mb-5 text-dark">Already Registered? <a href="/" className="text-dark fw-bold"> Sign In</a>
               </div>
            </form>
         );
      } else if (signupStep === "rstep3") {
         console.log("Returning Step 3");
         return (
            <div className="card-body cardbody-color p-lg-5">
               {/*
               <div className="file-upload">
                  <button className="file-upload-btn" type="button" onClick={() => $('.file-upload-input').trigger('click')}>Upload Driver's License</button>

                  <div className="image-upload-wrap">
                     <input className="file-upload-input" type='file' onChange={(event) => this.readURL(event.target)} accept="image/*" multiple />
                     <div className="drag-text">
                        <h3>Drag and drop a file or select add Image</h3>
                     </div>
                  </div>
                  <div className="file-upload-content">
                     <img className="file-upload-image" src="#" alt="your image" />
                     <div className="image-title-wrap">
                        <button type="button" onClick={(event) => this.removeUpload(event.target)} className="remove-image">Remove <span className="image-title">Uploaded Image</span></button>
                     </div>
                  </div>
               </div>
         */}
               <FileUpload onUpload={this.handleUpload} maxFileSize={1048576} maxFiles={2} startkey={1100}/>
               <div className="text-center">
                  <button type="button" onClick={() => this.props.changefunction("rstep4")} className="btn btn-primary px-5 mb-5 w-100">Next</button>
               </div>
               <div id="emailHelp" className="form-text text-center mb-5 text-dark">Already Registered? 
               <a href="/" className="text-dark fw-bold"> Sign In</a>
               </div>
            </div>
         );
      } else if (signupStep === "rstep4") {
         console.log("Returning Step 4");
         if (paymentmethod === "debit") {
         return (
            <form className="card-body cardbody-color p-lg-5">
               <div className="form-check">
                  <input className="form-check-input" type="radio" onClick={() => this.changePaymentMethod("debit")} name="flexRadioDefault" id="flexRadioDefault1" checked />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                     Pre-Authorized Debit
                  </label>
               </div>
               <div className="form-check">
                  <input className="form-check-input" type="radio" onClick={() => this.changePaymentMethod("credit")} name="flexRadioDefault" id="flexRadioDefault2" />
                     <label className="form-check-label" htmlFor="flexRadioDefault2">
                        Credit Card
                     </label>
               </div>
               <div className="row container-fluid border mb-3">
                <div className="col-md-6">
                  <label htmlFor="input-Bank-Name" className="form-label">Bank Name</label>
                  <input type="text" className="form-control" id="input-Bank-Name" placeholder="Royal Bank of Canada" />
                </div>
                <div className="col-md-6">
                  <label htmlFor="input-Account-Number" className="form-label">Account Number</label>
                  <input type="text" className="form-control" id="input-Account-Number" placeholder="102394785" />
                </div>
                <div className="col-md-6">
                  <label htmlFor="input-Routing-Number" className="form-label">Routing Number</label>
                  <input type="text" className="form-control" id="input-Routing-Number" placeholder="10546" />
                </div>
                <div className="col-md-6">
                  <label htmlFor="input-Account-Type" className="form-label">Account Type</label>
                  <select className="form-select" aria-label="Account Type" id="input-Account-Type" placeholder="C" >
                    <option value="C">Checking</option>
                    <option value="S">Savings</option>
                    <option value="O">Other</option>
                  </select>
                </div>
                <div className="col-md-6">
                  <label className="form-check-label" htmlFor="validated_chkbx"> Validated </label>
                  <input className="form-check mt-2" type="checkbox" id="validated_chkbx" disabled />
                </div>

               </div>
               <div className="text-center"><button type="button" onClick={() => this.props.changefunction("rstep5")} className="btn btn-primary px-5 mb-5 w-100">Next</button></div>
               <div id="emailHelp" className="form-text text-center mb-5 text-dark">Already Registered? <a href="/" className="text-dark fw-bold"> Sign In</a>
               </div>
            </form>);
         } else {
            return (
               <form className="card-body cardbody-color p-lg-5">
               <div className="form-check">
                  <input className="form-check-input" type="radio" onClick={() => this.changePaymentMethod("debit")} name="flexRadioDefault" id="flexRadioDefault1" />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                     Pre-Authorized Debit
                  </label>
               </div>
               <div className="form-check">
                  <input className="form-check-input" type="radio" onClick={() => this.changePaymentMethod("credit")} name="flexRadioDefault" id="flexRadioDefault2" checked />
                     <label className="form-check-label" htmlFor="flexRadioDefault2">
                        Credit Card
                     </label>
               </div>
               <div className="container-fluid border row mb-3">
               <div className="col-md-6">
                  <label htmlFor="input-Card-Type" className="form-label">Credit Card Type</label>
                  <select className="form-select" aria-label="Credit Card Type" id="input-Card-Type" placeholder="M" >
                    <option value="M">Mastercard</option>
                    <option value="V">Visa</option>
                    <option value="O">Other</option>
                  </select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="input-Card-Number" className="form-label">Credit Card Number</label>
                  <input type="text" className="form-control" id="input-Card-Number" placeholder="1234 3445 2314 2244" />
                </div>
                <div className="col-md-6">
                  <label htmlFor="input-Expiry-Month" className="form-label">Expiry</label>
                  <input type="text" className="form-control" id="input-Exprity-Month" placeholder="YYMM" />
                </div>
                <div className="col-md-6">
                  <label htmlFor="input-CCV" className="form-label">CCV</label>
                  <input type="text" className="form-control" id="input-CCV" placeholder="CCV" />
                </div>
                
                <div className="col-md-6">
                  <label className="form-check-label" htmlFor="validatedcc_chkbx"> Validated </label>
                  <input className="form-check mt-2" type="checkbox" id="validatedcc_chkbx" disabled />
                </div>
               </div>
               <div className="text-center">
                  <button type="button" onClick={() => this.props.changefunction("rstep5")} className="btn btn-primary px-5 mb-5 w-100">Next</button>
                  </div>
               <div id="emailHelp" className="form-text text-center mb-5 text-dark">Already Registered? <a href="/" className="text-dark fw-bold"> Sign In</a>
               </div>
            </form>);
            }
      } else if (signupStep === "rstep5") {
         console.log("Returning Step 5");
         return (
            <form className="card-body cardbody-color p-lg-5">
               <div className="mb-3">
                  <input type="password" className="form-control" id="userpassword" aria-describedby="emailHelp"
                     placeholder="Set Password" />
               </div>

               <div className="mb-3">
                  <input type="password" className="form-control" id="userpasswordagain" aria-describedby="emailHelp"
                     placeholder="Confirm Password" />
               </div>
               <div className="text-center">
                  <button type="button" onClick={() => this.props.changefunction("rstep6")} className="btn btn-primary px-5 mb-5 w-100">Next</button></div>
               <div id="emailHelp" className="form-text text-center mb-5 text-dark">Already Registered? <a href="/" className="text-dark fw-bold"> Sign In</a>
               </div>
            </form>
         );
      } else if (signupStep === "rstep6") {
         console.log("Returning Step 6");
         return (
            <form className="card-body cardbody-color p-lg-5">
                <div className="mb-3">
                  <label htmlFor="inputProgram" className="form-label">Program Type</label>
                  <select id="inputProgram" className="form-select" disabled>
                     <option selected>Furnace Rental (FRNT)</option>
                     <option>...</option>
                  </select>
               </div>
               <div className="mb-3">
                  <label htmlFor="inputCategory" className="form-label">Program Category</label>
                  <select id="inputCategory" className="form-select" disabled>
                     <option selected>Furnace (FURN)</option>
                     <option>...</option>
                  </select>
               </div>

               <div className="mb-3">
                  <label htmlFor="inputStartDate" className="form-label">Start Date</label>
                  <input type="date" className="form-control" id="inputStartDate" aria-describedby="emailHelp"
                     placeholder="YYYY/MM/DD" />
               </div>
               <div className="mb-3">
                  <label htmlFor="inputProgramAmount" className="form-label">Amount</label>
                  <input type="date" className="form-control" id="inputProgramAmount" aria-describedby="emailHelp"
                     placeholder="25.00" disabled/>
               </div>
               <div className="text-center"><button type="button" onClick={() => this.props.changefunction("rstep7")} className="btn btn-primary px-5 mb-5 w-100">Next</button></div>
               <div id="emailHelp" className="form-text text-center mb-5 text-dark">Already Registered? <a href="/" className="text-dark fw-bold"> Sign In</a>
               </div>
            </form>
         );
      } else if (signupStep === "rstep7") {
         console.log("Returning Step 7");
         return (
            <form className="card-body cardbody-color p-lg-5">
               <div className="mb-3">
                  <input type="text" className="form-control" id="customername" aria-describedby="emailHelp"
                     placeholder="Your Name" />
               </div>

               <div className="mb-3">
                  <input type="tel" className="form-control" id="homephone" aria-describedby="emailHelp"
                     placeholder="Home Phone" />
               </div>
               <div className="input-group mb-3">
                  <input type="tel" className="form-control" id="cellphone" aria-describedby="emailHelp"
                     placeholder="Cell Phone" />
                  <span className="input-group-text">
                     <div className="form-check"
                        aria-describedby="inputGroup-sizing-sm">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                        <label className="form-check-label" htmlFor="flexCheckChecked">Allow SMS?
                        </label>
                     </div>
                  </span>
               </div>
               <div className="mb-3">
                  <input type="email" className="form-control" id="customeremail" aria-describedby="emailHelp"
                     placeholder="Your Email" />
               </div>
               <div className="text-center"><button type="button" className="btn btn-primary px-5 mb-5 w-100">Finish</button></div>
               <div id="emailHelp" className="form-text text-center mb-5 text-dark">Already Registered? <a href="/" className="text-dark fw-bold"> Sign In</a>
               </div>
            </form>
         );
      } else if (data == null) {
         return
         (<div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
               <div className="container">Not Logged In - Do you want to register?</div>;
            </div>
         </div>);
      }
   }
}

export default SignupStep;