import $ from 'jquery';
import React from 'react';
//import icons from "@fortawesome/free-solid-svg-icons";
//import translogo from '../img/final.png';
import logo from '../img/nologo.png';
import transicon from '../img/trekiconnb.png';

type NavigationItem = 'profile' | 'properties' | 'programs' |'billhistory'|'payments'|'assets'; 
interface NavigationProps {
    selectedNavItem  : NavigationItem,
    setSelectedNavItem: (navItem: NavigationItem) => void;
  }

 
const Navigation: React.FC<NavigationProps> = ({ selectedNavItem, setSelectedNavItem }) => {
    
  console.log("Navigation rendered");
  const handleNavigationItemClick = (e: any, navItem: NavigationItem) => {
    e.preventDefault();
    if (selectedNavItem !== navItem) {
   // alert('Menu Item Clicked '+navItem);
    setSelectedNavItem(navItem);
    console.log("Selected Menu item"+navItem);
    }
  };
    (window as any).jquery = $;

    function handleClik() {
        //alert('Button Clicked');
        $("body").toggleClass("sidebar-toggled");
        $(".sidebar").toggleClass("toggled");
        /*
        if ($(".sidebar").hasClass("toggled")) {
              //$('.sidebar .collapse').collapse('hide');
              alert('Hiding');
        };
        */
    }
    /*
    function handleSubMenu() {
        //  alert('Sub Menu Clicked');
    }
*/
    return (
        <>
            {/* Sidebar */}
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

                {/* Sidebar - Brand */}
                <a className="sidebar-brand d-flex text-success align-items-center justify-content-center" href="/" onClick={(e) =>  handleNavigationItemClick(e,'profile')}>
                    <div className="sidebar-brand-icon rotate-n-15">
                        {/* <FontAwesomeIcon icon={icon({name: 'face-laugh-wink'})} /> */}
                        <img src={transicon} width="40" alt="TrekSoftware"></img>
                    </div>
                    <div className="sidebar-brand-text mx-1 " style={{ color: '#38EF7D' }}>Flex Customer Portal</div>
                </a>
                {/* Divider */}
                <hr className="sidebar-divider my-0" />

                {/*<!-- Nav Item - Dashboard -->*/}
                <li className={selectedNavItem === 'profile' ? 'nav-item active' : 'nav-item '} onClick={(e) =>  handleNavigationItemClick(e, 'profile')} >
                    <a className="nav-link" href="/">
                    <i className="bi bi-person-rolodex"></i>
                        <span>My Account</span></a>
                </li>

                {/*<!-- Divider -->*/}
                <hr className="sidebar-divider" />
                 {/*<!-- Heading -->*/}
                {/*<!-- Nav Item - Pages Collapse Menu -->*/}
                <li className={selectedNavItem === 'properties' ? 'nav-item active' : 'nav-item '} onClick={(e) =>  handleNavigationItemClick(e,'properties')} >
                    <a className="nav-link collapsed" href="/" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseTwo">
                        <i className="bi bi-buildings"></i>
                        <span> My Properties</span>
                    </a>
                </li>
                {/*<!-- Nav Item - Pages Collapse Menu -->*/}
                <li className={selectedNavItem === 'programs' ? 'nav-item active' : 'nav-item '} onClick={(e) =>  handleNavigationItemClick(e,'programs')} >
                    <a className="nav-link collapsed" href="/" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseTwo">
                        <i className="bi bi-list-stars"></i>
                        <span> My Programs</span>
                    </a>
                </li>
                {/*<!-- Nav Item - Pages Collapse Menu -->*/}
                <li className={selectedNavItem === 'billhistory' ? 'nav-item active' : 'nav-item '} onClick={(e) =>  handleNavigationItemClick(e,'billhistory')} >
                    <a className="nav-link collapsed" href="/" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseTwo">
                        <i className="bi bi-calendar-check"></i>
                        <span> Billing History</span>
                    </a>
                </li>
                {/*<!-- Nav Item - Pages Collapse Menu -->*/}
                <li className={selectedNavItem === 'payments' ? 'nav-item active' : 'nav-item '} onClick={(e) =>  handleNavigationItemClick(e,'payments')} >
                    <a className="nav-link collapsed" href="/" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseTwo">
                        <i className="bi bi-cash-coin"></i>
                        <span> Payments</span>
                    </a>
                </li>
                 {/*<!-- Nav Item - Pages Collapse Menu -->*/}
                 <li className={selectedNavItem === 'assets' ? 'nav-item active' : 'nav-item '} onClick={(e) =>  handleNavigationItemClick(e,'assets')} >
                    <a className="nav-link collapsed" href="/" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseTwo">
                        <i className="bi bi-bar-chart-line"></i>
                        <span> Assets</span>
                    </a>
                </li>
                
                {/*<!-- Divider -->*/}
                <hr className="sidebar-divider d-none d-md-block" />

                {/*<!-- Sidebar Toggler (Sidebar) -->*/}
                <div className="text-center d-none d-md-inline">
                    <button className="rounded-circle border-0" onClick={handleClik} id="sidebarToggle"></button>
                </div>

                {/*<!-- Sidebar Message -->*/}
                <div className="sidebar-card d-none d-lg-flex">
                    <img src={logo} width="90%" alt="" />
                </div>

            </ul>
            {/*<!-- End of Sidebar -->*/}
        </>
    );
}

export default Navigation;