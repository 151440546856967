import React, { useRef, useState } from 'react';
import docimage from '../img/docimage.jpg';
import pdfimage from '../img/pdfimage.jpg';

interface FileUploadProps {
  onUpload: (files: File[] | null) => void;
  maxFileSize: number;
  maxFiles: number;
  startkey: number;
}

const FileUpload: React.FC<FileUploadProps> = ({ onUpload, maxFileSize, maxFiles, startkey }) => {
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const selectedFiles = Array.from(e.dataTransfer.files);
    const newFiles = selectedFiles.filter((file) => file.size <= maxFileSize);
    if (selectedFiles.length > newFiles.length) {
      setErrors(['File Size too big! Please make sure the file is less than '+maxFileSize+' bytes']);
      return;
    }
    if ((newFiles.length + files.length) > maxFiles) {
      setErrors(['Exceeded maximum limit of ' + maxFiles + (maxFiles === 1 ? ' file' : ' files')]);
      return;
    }
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setErrors([]);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Handling FileChange");
    const selectedFiles = Array.from(e.target.files || []);
    const newFiles = selectedFiles.filter((file) => file.size <= maxFileSize);
    if (selectedFiles.length > newFiles.length) {
      setErrors(['File Size too big! Please make sure the file is less than '+maxFileSize+' bytes']);
      return;
    }
    //setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    console.log("New Files "+newFiles.length+" Existing Files: "+files.length);
    if ((newFiles.length + files.length) > maxFiles) {
      setErrors(['Exceeded maximum limit of ' + maxFiles + (maxFiles === 1 ? ' file' : ' files')]);
      return;
    }
    const updatedFiles = [...files, ...newFiles];
    setFiles(updatedFiles);
    setErrors([]);

    // Optional: Generate file previews using FileReader API
    /*    
        newFiles.forEach((file) => {
          const reader = new FileReader();
          reader.onload = () => {
            // Perform any preview display logic here
            console.log('File preview:', reader.result);
          };
          reader.readAsDataURL(file);
        });
      };
      */
  };

  const handleUpload = () => {
    onUpload(files);
    setFiles([]);
    /*
    if (fileInput) {
      fileInput.value = '';
    }
    
    setFiles([]);
    onUpload(files);
    */
  };

  const handleRemoveFile = (file: File) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };

  const simulateClick = () => {
    console.log("Simulating Click");
    inputFileRef.current!.click();
    console.log("After Simulating Click");
  }

  return (
    <div className="file-upload-main">
      <button className="file-upload-btn" type="button" onClick={simulateClick}>Select Files</button>
      <div key={startkey++}
        className={`file-upload ${dragging ? 'dragging' : ''}`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={simulateClick}
      >

        <div className="file-upload__drop-area">
          <input
            id="file-input"
            key={startkey++}
            className="file-upload-input"
            ref={inputFileRef}
            type="file"
            multiple
            onInput={handleFileChange}
            accept=".pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*"
          />
          <p>Drag and drop files here or click to browse</p>
        </div>
        {errors.length > 0 && <div className="text-danger">{errors.join(' ')}</div>}
      </div>
      <div className="file-upload__file-list">
        {files.map((file) => (
           /*<div className="file-upload-content" key={file.name}>
             <img className="file-upload-image" src={URL.createObjectURL(file)} alt={file.name} />
                <div className="image-title-wrap">
                   <button type="button" onClick={() => handleRemoveFile(file)} className="remove-image">Remove 
                   <span className="image-title">Uploaded Image</span></button>
                 </div>
           </div>
           */
          <div key={startkey++} className="file-upload__file">
            <div key={startkey++} className="file-upload__file-preview">
              <img key={startkey++} src={file.name.toLowerCase().endsWith(".pdf")?pdfimage:file.name.toLowerCase().endsWith(".docx")?docimage:URL.createObjectURL(file)} alt={file.name+'['+file.type+']'} />
            </div>
            <div key={startkey++} className="file-upload__file-name">{file.name}</div>
            <div className="file-upload__file-remove">
              <button onClick={() => handleRemoveFile(file)} className="remove-image">Remove</button>
            </div>
          </div>
          
        ))}
      </div>
      {files.length > 0 && (
        <div key={startkey++} className="file-upload__upload-button">
          <button onClick={handleUpload}>Upload</button>
        </div>
      )}

    </div>
  );
};

export default FileUpload;
