import { Document, Image, Line, Page, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';
import React from 'react';
import treklogo from '../img/Transparent.png';
import arctlogo from '../img/nologo.png';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFE4'
  },
  box: { width: '100%', marginBottom: 30, borderRadius: 5 },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    color: 'green', textAlign: 'left'
  },
  pageNumbers: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 10,
    right: 0,
    width: 100,
    textAlign: 'left'
  },
  image: {
    flexGrow: 0,
    margin: 20,
    width: 100
  }
});

interface DataProps {
    billdata: string;
  }
  
// Create Document Component
const MyPDFPage: React.FC<DataProps> = (props) => {
    let myjsondata = JSON.parse(props.billdata);
    return (
  <Document pageMode="fullScreen">
    <Page size="A4" style={styles.page}>
    <View style={{ height: 100, textAlign: 'right', width: '100' }}> 
    <Image style={{ marginHorizontal: '10%', width: 'auto', height: 'auto' }} src={arctlogo} /> 
    </View>
      <View style={styles.section}>
        <Image src={arctlogo} style={{width: "80" }} source="Arctic Home Services" />
        <Svg height="5" width="600">
        <Line
          x1="0"
          y1="0"
          x2="580"
          y2="0"
          strokeWidth={5}
          stroke="rgb(255,255,0)"
        />
      </Svg>
        <Text>Bill Reference: {myjsondata['Reference Number']}</Text>
        <Text>Customer: {myjsondata['Customer']}</Text>
        <Text>Address: {myjsondata['Location']}</Text>
      </View>
      <View style={styles.section}>
        <Text>Bill Date: {myjsondata['Bill Date']}</Text>
        <Text>Due Date: {myjsondata['Due Date']}</Text>
        <Text>Amount Due: {myjsondata['Amount Due']}</Text>
      </View>
      <View style={[styles.box, { height: 600, backgroundColor: 'deepskyblue' }]} />
      <Image style={styles.footer} src={treklogo} fixed />
      <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
         `Page: ${pageNumber} of ${totalPages}`
      )} fixed />
    </Page>
  </Document>
    )
}

export default MyPDFPage;