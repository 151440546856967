import React, { useContext, useState } from "react";
import ReactDOM from 'react-dom';
import { PDFViewer } from '@react-pdf/renderer';
import DataForm from "./DataForm";
import AppContext from "../context/AppContext";
import FileUpload from "./FileUpload";
import HistoryForm from "./HistoryForm";
import MasterDetailForm from "./MasterDetail";
import MyPDFPage from "./MyPDFPage";

interface PageProps {
    pagename: string
}

const PageContents: React.FC<PageProps> = React.memo(({ pagename }) => {
    const [error, setError] = useState('');
    const { sessionData } = useContext(AppContext);

    const handleUpload = (files: FileList|null) => {
        // Perform upload logic here
        if (files == null) {
            console.log("No Files to upload");
        } else {
        console.log('Uploading files:', files);
        }
        return null;
      };
    
    if (sessionData == null) {
        console.log("In PageContents - User Not Logged In");
       setError( 'User Session Expired. Please login again.' );
    }
    if (error) {
        return (
        <div><h1>Error {error} To login again, please <a href="/">click here</a></h1></div>
        );
    }
    
    if (pagename === "profile") {
            return (
                
                <div className="container-fluid">
                    {/*JSON.stringify(sessionData) 
                    <h1> Customer Id is {sessionData.data["Customer Id"]}</h1>
                    {/*<FileUpload onUpload={handleUpload} maxFileSize={1048576} maxFiles={2}/> */}
                    <div className="card">
                    <div className="card-header">
                        <h1>Profile</h1>
                    </div>
                   </div>
                    <DataForm pagename={pagename} />
                </div>

            )
    } else if (pagename === "billhistory") {
                return (
                    <div className="container-fluid">
                        <MasterDetailForm master={pagename} detail="billdetail" />
                    </div>
                )            
    } else {
        return (
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header">
                        <h1>Displaying Form for {pagename}</h1>
                    </div>
                </div>
                <DataForm pagename={pagename} />
            </div>);
        
    }
})
export default PageContents;