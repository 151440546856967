import { PDFViewer } from '@react-pdf/renderer';
import React, { useState } from 'react';
import {Button, Modal} from 'react-bootstrap';
import MyPDFPage from './MyPDFPage';

interface DataProps {
  pdftitle : string;
  msgobj: string;
  clearParam: (value :any ) =>void;
}
const PDFModalViewer: React.FC<DataProps> = (props) => {
  const [width, setWidth] = useState(400);
  const [height, setHeight] = useState(300);
  const [showModal, setShowModal] = useState(true);
  console.log(props.msgobj+" "+props.pdftitle);
  //const msgjson = JSON.parse(props.msgobj);
 console.log("PDF Modal Viewer is called");
 
  const handleResize = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { clientWidth, clientHeight } = event.currentTarget;
    setWidth(clientWidth);
    setHeight(clientHeight);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };


  return (
    <div>
        {/*<div className="container mt-3">
        <h3>Extra Large Modal Example</h3>
        <p>Click on the button to open the modal.</p>
        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#myModal">
          Open modal
        </button>
  </div> */}
      
      <div className="modal fade" id="myModal" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{props.pdftitle}</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body"  style= {{height: "600px"}} >
             <PDFViewer width="100%" height="100%">
               <MyPDFPage billdata={props.msgobj}/>
               </PDFViewer>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
            </div>
      
          </div>
        </div>
      </div>
    </div>
  );
};

export default PDFModalViewer;
